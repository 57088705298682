@property --color-rotate {
  syntax: "<color>";
  inherits: true;
  initial-value: #ec6467;
}

@property --color-rotate-r {
  syntax: "<number>";
  inherits: true;
  initial-value: 236;
}

@property --color-rotate-g {
  syntax: "<number>";
  inherits: true;
  initial-value: 100;
}

@property --color-rotate-b {
  syntax: "<number>";
  inherits: true;
  initial-value: 103;
}

:root {
  --light: #eff1f5;
  --dark: #333;
  --color-rotate-rgb: var(--color-rotate-r), var(--color-rotate-g), var(--color-rotate-b);
  --color-rotate: rgb(var(--color-rotate-rgb));
  --highlight: var(--color-rotate);
  --highlight-r: var(--color-rotate-r);
  --highlight-g: var(--color-rotate-g);
  --highlight-b: var(--color-rotate-b);
  background: var(--bg);
  color: var(--fg);
  --bg: var(--dark);
  --fg: var(--light);
  animation: 60s infinite color-cycle;
}

@media (prefers-color-scheme: light) {
  :root {
    --bg: var(--light);
    --fg: var(--dark);
  }
}

@keyframes color-cycle {
  8.33333% {
    --color-rotate-r: 208;
    --color-rotate-g: 135;
    --color-rotate-b: 112;
  }

  25% {
    --color-rotate-r: 235;
    --color-rotate-g: 203;
    --color-rotate-b: 139;
  }

  41.6667% {
    --color-rotate-r: 163;
    --color-rotate-g: 190;
    --color-rotate-b: 140;
  }

  58.3333% {
    --color-rotate-r: 136;
    --color-rotate-g: 171;
    --color-rotate-b: 206;
  }

  75% {
    --color-rotate-r: 180;
    --color-rotate-g: 142;
    --color-rotate-b: 173;
  }

  91.6667% {
    --color-rotate-r: 175;
    --color-rotate-g: 143;
    --color-rotate-b: 226;
  }
}

::selection {
  background: var(--color-rotate);
  color: var(--dark);
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  min-height: 100%;
  box-sizing: border-box;
  margin: 0;
  font-family: Fira Code, monospace;
  font-size: clamp(1em, 4vw, 1.25em);
  display: grid;
}

body:before {
  border: .5em solid var(--highlight);
  pointer-events: none;
  content: "";
  z-index: 2;
  position: fixed;
  inset: 0;
}

.wrapper {
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 24em), 1fr));
  gap: 0 2em;
  margin: 0;
  display: grid;
}

header, main > div, footer {
  padding: 2.5em;
}

header {
  max-height: 100vh;
  min-height: 75vh;
  pointer-events: none;
  grid-row: span 2;
  align-content: center;
  display: grid;
  position: sticky;
  top: 0;
}

header > * {
  pointer-events: initial;
}

main {
  background: var(--highlight);
  margin-top: -4.16667vh;
  padding-top: 2.77778vh;
  position: relative;
}

main, main > div {
  -webkit-mask-image: url("squiggle-mask.b9849094.svg"), linear-gradient(#0000 2.77778vh, #000 2.77778vh);
  mask-image: url("squiggle-mask.b9849094.svg"), linear-gradient(#0000 2.77778vh, #000 2.77778vh);
  -webkit-mask-size: auto 2.77778vh, auto;
  mask-size: auto 2.77778vh, auto;
}

main > div {
  background: var(--bg);
}

h1 {
  flex-wrap: wrap;
  place-items: end flex-start;
  margin: 0 0 .25em;
  font-size: 3em;
  line-height: 1;
  display: flex;
  position: relative;
}

h1 > span:first-of-type {
  flex: none;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-items: end;
  display: grid;
}

h1 > span:last-of-type {
  font-size: .3em;
  line-height: 1.8;
}

footer {
  background: var(--highlight);
  color: var(--dark);
  min-height: 8em;
  border-top-left-radius: 4em;
  position: relative;
}

footer > div {
  font-size: 2em;
  font-weight: bold;
}

footer:before, footer:after {
  background: inherit;
  content: "";
  width: 4em;
  height: 4em;
  position: absolute;
  -webkit-mask-image: radial-gradient(at 0 0, #0000 4em, #000 4em);
  mask-image: radial-gradient(at 0 0, #0000 4em, #000 4em);
}

footer:before {
  bottom: 100%;
  right: .5em;
}

footer:after {
  bottom: .5em;
  right: 100%;
}

svg {
  width: 100%;
  margin-top: .2em;
  display: block;
}

strong {
  color: var(--highlight);
}

.highlight {
  color: var(--highlight);
  font-weight: 700;
  transition: color .2s;
}

.highlight[class*="with background"] {
  --offset: 45%;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background: linear-gradient(to bottom, rgba(var(--color-rotate-rgb), 0) var(--offset), rgba(var(--color-rotate-rgb), .1) var(--offset), rgba(var(--color-rotate-rgb), .1));
  margin: 0 -.2em;
  padding: 0 .2em;
}

/*# sourceMappingURL=index.af4a3c52.css.map */
