@property --color-rotate {
  syntax: '<color>';
  inherits: true;
  initial-value: rgb(236, 100, 103);
}

@property --color-rotate-r {
  syntax: '<number>';
  inherits: true;
  initial-value: 236;
}

@property --color-rotate-g {
  syntax: '<number>';
  inherits: true;
  initial-value: 100;
}

@property --color-rotate-b {
  syntax: '<number>';
  inherits: true;
  initial-value: 103;
}

:root {
  --light: #eff1f5;
  --dark: #333;

  --color-rotate-rgb: var(--color-rotate-r), var(--color-rotate-g),
    var(--color-rotate-b);
  --color-rotate: rgb(var(--color-rotate-rgb));

  --highlight: var(--color-rotate);
  --highlight-r: var(--color-rotate-r);
  --highlight-g: var(--color-rotate-g);
  --highlight-b: var(--color-rotate-b);

  animation: color-cycle 60s infinite;
  background: var(--bg);
  color: var(--fg);

  --bg: var(--dark);
  --fg: var(--light);

  @media (prefers-color-scheme: light) {
    --bg: var(--light);
    --fg: var(--dark);
  }
}

@keyframes color-cycle {
  8.333333333333334% {
    --color-rotate-r: 208;
    --color-rotate-g: 135;
    --color-rotate-b: 112;
  }

  25% {
    --color-rotate-r: 235;
    --color-rotate-g: 203;
    --color-rotate-b: 139;
  }

  41.666666666666664% {
    --color-rotate-r: 163;
    --color-rotate-g: 190;
    --color-rotate-b: 140;
  }

  58.333333333333336% {
    --color-rotate-r: 136;
    --color-rotate-g: 171;
    --color-rotate-b: 206;
  }

  75.00000000000001% {
    --color-rotate-r: 180;
    --color-rotate-g: 142;
    --color-rotate-b: 173;
  }

  91.66666666666667% {
    --color-rotate-r: 175;
    --color-rotate-g: 143;
    --color-rotate-b: 226;
  }
}

::selection {
  background: var(--color-rotate);
  color: var(--dark);
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  display: grid;
  min-height: 100%;
  font-family: 'Fira Code', monospace;
  margin: 0;
  box-sizing: border-box;
  font-size: clamp(1em, 4vw, 1.25em);

  &::before {
    border: 0.5em solid var(--highlight);
    pointer-events: none;
    content: '';
    position: fixed;
    inset: 0;
    z-index: 2;
  }
}

.wrapper {
  display: grid;
  gap: 0 2em;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 24em), 1fr));
  margin: 0;
  width: 100%;
}

header,
main > div,
footer {
  padding: calc(2em + 0.5em);
}

header {
  align-content: center;
  display: grid;
  max-height: 100vh;
  min-height: 75vh;
  grid-row: span 2;
  position: sticky;
  pointer-events: none;
  top: 0;

  > * {
    pointer-events: initial;
  }
}

main {
  position: relative;
  background: var(--highlight);
  padding-top: 2.7777777777777777vh;
  margin-top: calc(-2.7777777777777777vh * 1.5);

  &,
  > div {
    mask-image: url('../squiggle-mask.svg'),
      linear-gradient(
        to bottom,
        transparent 2.7777777777777777vh,
        black 2.7777777777777777vh
      );
    mask-size: auto 2.7777777777777777vh, auto;
  }

  > div {
    background: var(--bg);
  }
}

h1 {
  align-items: end;
  display: flex;
  font-size: 3em;
  line-height: 1;
  margin: 0 0 0.25em;
  position: relative;
  flex-wrap: wrap;
  justify-items: flex-start;

  > span:first-of-type {
    align-items: end;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    flex: 0 0 auto;
  }

  > span:last-of-type {
    font-size: 0.3em;
    line-height: 1.8;
  }
}

footer {
  background: var(--highlight);
  border-top-left-radius: 4em;
  color: var(--dark);
  position: relative;
  min-height: 8em;

  > div {
    font-size: 2em;
    font-weight: bold;
  }

  &::before,
  &::after {
    background: inherit;
    content: '';
    position: absolute;
    width: 4em;
    height: 4em;
    mask-image: radial-gradient(ellipse at 0 0, transparent 4em, black 4em);
  }

  &::before {
    bottom: 100%;
    right: 0.5em;
  }

  &::after {
    right: 100%;
    bottom: 0.5em;
  }
}

svg {
  display: block;
  margin-top: 0.2em;
  width: 100%;
}

strong {
  color: var(--highlight);
}

.highlight {
  color: var(--highlight);
  font-weight: 700;
  transition: color 0.2s;

  &[class*='with background'] {
    --offset: 45%;

    box-decoration-break: clone;
    background: linear-gradient(
      to bottom,
      rgba(var(--color-rotate-rgb), 0) var(--offset),
      rgba(var(--color-rotate-rgb), 0.1) var(--offset),
      rgba(var(--color-rotate-rgb), 0.1)
    );
    padding: 0 0.2em;
    margin: 0 -0.2em;
  }
}
